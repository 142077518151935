import {useEffect, useContext} from "react";
import {useHistory, useLocation} from "react-router-dom";
import UserContext from "../contexts/UserContext";
import decodeJwt from 'jwt-decode';
import {redirectToRails} from "./helpers/rails-helpers";
import axiosAuth from "../utils/axiosWithAuth";

const DEFAULT_WIDGET_OPTIONS = {
    includeCosts: true,
    includeUsage: true,
    propertySelect: true,

}

export default function CheckToken() {
    const {user, setUser, setActiveTheme, setWidgetOptions} = useContext(UserContext)
    const url = window.location.href
    const history = useHistory()
    const location = useLocation()
    const storedJwt = localStorage.getItem("jwt")

    useEffect(_ => {
        const searchParams = new URLSearchParams(location.search)
        const urlJwt = searchParams.get('jwt');
        const widgetUrlJwt = searchParams.get('widgetToken');
        const qboId = searchParams.get('qbo_id');
        const tbId = searchParams.get('tb_id') || "";
        const companyId = searchParams.get('company_id') || "";

        if (widgetUrlJwt) {
            let decodedJwt = decodeJwt(widgetUrlJwt)
            localStorage.setItem('widgetJwt', widgetUrlJwt)
            // potentially set theme-color and darkMode here
            setUser({...user, loggedIn: true, admin: false, os: false})
            const newUrl = new URL(url);
            newUrl.searchParams.delete('widgetToken');
            setWidgetOptions(decodedJwt)
        } else if (urlJwt) {
            localStorage.removeItem('widgetJwt', widgetUrlJwt)
            const decodedJwt = decodeJwt(urlJwt)
            localStorage.setItem('jwt', urlJwt)   
            setUser({...user, loggedIn: true, admin: decodedJwt.admin, os: decodedJwt.os, id: decodedJwt.user_id})
            setWidgetOptions(DEFAULT_WIDGET_OPTIONS)
            if (url.includes("request")) {
                history.push("/request")
            } else if (url.includes("source_bill_files")) {
                history.push("/source_bill_files")
            } else if (url.includes("invoyage")) {
                history.push(`/invoyage/${tbId}`)
            } 
            else if (url.includes("quickbooks")) {
                qboId ? history.push("/quickbooks?qbo_id=" + qboId) : history.push("/quickbooks")
            } else if (url.includes("billing_portfolios")) {
                companyId ? history.push("/billing_portfolios/" + companyId) : history.push("/billing_portfolios")
            } else if (url.includes("widgets")) {
                const newUrl = new URL(url);
                newUrl.searchParams.delete('jwt');
                history.push(newUrl.pathname + newUrl.search);
            }
            else {
                redirectToRails();
            }
            // right now just always redirect rails traffic to checkout
        } else if (storedJwt) {
            localStorage.removeItem('widgetJwt', widgetUrlJwt)
          const decodedJwt = decodeJwt(storedJwt)
          setWidgetOptions(DEFAULT_WIDGET_OPTIONS)
          if (decodedJwt.admin) {
              setUser({...user, admin: true, os: decodedJwt.os, id: decodedJwt.user_id})
          }
          if (decodedJwt.exp < (Date.now() / 1000)) {
              localStorage.removeItem('jwt')
              setUser({...user, loggedIn: false})
              history.push('/login?expired=true')
          }
        } 

        if (storedJwt || urlJwt || widgetUrlJwt) {
            axiosAuth().get("/auth/checkToken")
            .then(r => console.log("Token is valid"))
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    localStorage.removeItem('jwt')
                    localStorage.removeItem('widgetToken')
                    setUser({...user, loggedIn: false})
                    history.push('/login?expired=true')
                }
            })
        }

      
    },[])

    return(
        <div className="token-checker-component"></div>
    )
}
