import {useState, useEffect, useContext} from "react";
import {ListSubheader, MenuItem} from "@material-ui/core"
import TabPanel from "../request-emails/TabPanel"
import Index from "./Index"
import ShowBill from "./bill/ShowBill"
import TextractContext from "../../contexts/TextractContext"
import TextractAppBar from "./TextractAppBar";
import TextractSnackbar from "./snackbar/TextractSnackbar";
import Configuration from "./configuration/Configuration"
import {Route} from "react-router-dom"
import {useHistory, useParams} from 'react-router-dom'
import Mappings from "./mappings/Mappings"
import { seo } from "../helpers/seo";
import PromptNotes from "./prompts/PromptNotes"
import axiosAuth from "../../utils/axiosWithAuth";
import "./Textract.scss";
import useLocalStorage from "../../hooks/useLocalStorage";
import { parseQueryString } from "../helpers/string-helpers";
import ModeTabs from "./ModeTabs";
import Intake from "./intake/Intake";
import { IntakeAccountProvider } from "../../contexts/AccountContext";
import UserContext from "../../contexts/UserContext";
import Validation from "./validation/Validation"


export default function TextractContainer() {
  const history = useHistory()
  const CONFIGURATION_BASE = {
    name: "Loading...",
    queries: [],
    ai_model: "o3-mini-2025-01-31",
    use_forms: false,
    use_tables: false,
    use_queries: true,
    use_expense: false,
    no_lighting_usage: false,
    storm_water_always_one_esu: true,
    provides_sewer_usage: false,
    gets_usage_from_queries: false
  }
  const {user} = useContext(UserContext)
  const [fetchNew, setFetchNew] = useState(false)
  const [utilityCompanies, setUtilityCompanies] = useState([])
  const [accounts, setAccounts] = useState([])
  const [uams, setUams] = useState([])
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [selectedUtilityCompany, setSelectedUtilityCompany] = useState(null)
  const [loadingQueue, setLoadingQueue] = useState(false)
  const [tabIndex, setTabIndex] = useState(1)
  const [textractBills, setTextractBills] = useState([])
  const [configurations, setConfigurations] = useState([])
  const [selectedTextractBill, setSelectedTextractBill] = useState(null)
  const [selectedConfiguration, setSelectedConfiguration] = useState(CONFIGURATION_BASE)
  const [selectedTextractBillId, setSelectedTextractBillId] = useState(null)
  const [groupedConfigurations, setGroupedConfigurations] = useState([<ListSubheader>Loading...</ListSubheader>])
  const [currentScaffold, setCurrentScaffold] = useState(null)
  const [dateRange, setDateRange] = useState({})
  const [modeTabIndex, setModeTabIndex] = useState(1)
  const [snackbar, setSnackbar] = useState({
    message: "",
    severity: "success"
  })
  const [filters, setFilters] = useLocalStorage('invoyageFilters',{
    status: "ALL",
    page: 0,
    rowsPerPage: 25,
    sort: 'tb.id',
    order: 'desc',
    query: "",
    utility_company_id: "all",
    delivery_id: "All",
    processed_by_user_id: "all"
  }, parseQueryString())

  const deselectBill = _ => {
    setSelectedTextractBillId(null)
    setSelectedTextractBill(null)
    history.push("/Invoiage")
  }


  const fetchConfigurationById = (configId) => {
    axiosAuth().get(`/admin/textract_configurations/${configId}`).then(response => {
      setSelectedConfiguration(response.data.configuration)
    })
  }


  useEffect(_ => {
    seo({
      title: "Invoiage"
    })


    axiosAuth().get("/admin/textract_configurations")
    .then(r => {
      setConfigurations(r.data.configurations)

      fetchConfigurationById(r.data.configurations[0].id);
    }).catch(console.error)

    axiosAuth().get(`/admin/utility_companies`)
    .then(r => {
      setUtilityCompanies(r.data)
    })
    .catch(err => console.error(err.response))

    axiosAuth().get("/admin/textract_bills")
    .then(r => {
      setTextractBills(r.data.textractBills)
    })
    .catch(console.error)
  }, [])

  useEffect(_ => {
      const h = {Metadata: [], Utilities: [], Unmapped: []}
      configurations.forEach((t) => t.name == "Invoice Metadata" 
                                    ? h.Metadata.push(t) 
                                    : t.utility_company_id 
                                      ? h.Utilities.push(t)
                                      : h.Unmapped.push(t))
      const configSelect = []
      Object.keys(h).forEach(key => {
          configSelect.push(<ListSubheader style={{fontSize: "1.25rem"}}>{key}</ListSubheader>)
          h[key].sort((a,b) => a.name.localeCompare(b.name)).forEach(config => {
              configSelect.push(<MenuItem key={"config-" + config.id} value={config.id}>{config.name}</MenuItem>)
          })
      })
      setGroupedConfigurations(configSelect)
  }, [configurations])

  useEffect(_ => {
    if (!selectedTextractBillId) {
      setSelectedTextractBill(null)
      return
    }

  }, [selectedTextractBillId])

  useEffect(_ => {
    if (selectedTextractBill && selectedTextractBill.utility_company_id) {
      const foundConfig = configurations.find(config => config.utility_company_id == selectedTextractBill.utility_company_id)
      if (foundConfig) {
        fetchConfigurationById(foundConfig.id)
      }
    }

  }, [selectedTextractBill])

  
  return (
    <TextractContext.Provider value={{dateRange, setDateRange, currentScaffold, setCurrentScaffold, loadingQueue, setLoadingQueue, groupedConfigurations, fetchNew, setFetchNew, uams, setUams, utilityCompanies, setUtilityCompanies, accounts, setAccounts, selectedAccount, setSelectedAccount, selectedUtilityCompany, setSelectedUtilityCompany, filters, setFilters, deselectBill, CONFIGURATION_BASE, configurations, setConfigurations, tabIndex, setTabIndex, selectedConfiguration, setSelectedConfiguration, textractBills, setTextractBills, selectedTextractBill, setSelectedTextractBill, selectedTextractBillId, setSelectedTextractBillId, snackbar, setSnackbar}}>
      <>
        <ModeTabs index={modeTabIndex} setIndex={setModeTabIndex} />
        <TabPanel boxPadding={0} value={modeTabIndex} index={0} >
          <IntakeAccountProvider>
            <Intake />
          </IntakeAccountProvider>
        </TabPanel>
        <TabPanel boxPadding={0} value={modeTabIndex} index={1}>
          <TextractAppBar />
          <TabPanel value={tabIndex} index={0}>
            <Configuration />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <Route exact path="/Invoiage" component={Index} />
            <Route path="/Invoiage/:id" component={ShowBill} />
            {/* {
              !!selectedTextractBill 
              ? <ShowBill />
              : <Index />
            } */}
          </TabPanel>
          <TabPanel value={tabIndex} index={2}>
            <Mappings />
          </TabPanel>
          <TabPanel value={tabIndex} index={3}>
            <PromptNotes />
          </TabPanel>
        </TabPanel>
        <TabPanel boxPadding={0} value={modeTabIndex} index={2} >
          <Validation />
        </TabPanel>
        <TextractSnackbar 
          snackbar={snackbar}
          setSnackbar={setSnackbar}
        />
      </>
    </TextractContext.Provider>
    
  )
}
