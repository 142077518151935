// /var/www/ep-api-v1/textract/shared/uamMatching.js

const UTILITY_MAPPINGS = Object.freeze([
  { keywords: [/tower/i], utilityType: ["Tower"] },
  { keywords: [/StormWater|surfacewater|drainage|storm|cleanriversiac|riversmart|Parcel|^STRM/i], utilityType: ["Storm"] },
  { keywords: [/fireservice|fireline/i], utilityType: ["Fireline"] },
  { keywords: [/solar|receivedenergypayment/i], utilityType: ["Solar"] },
  { keywords: [/chilled/i], utilityType: ["Chilled"] },
  { keywords: [/hotwater/i], utilityType: ["Hot Water"] },
  { keywords: [/fueloil/i], utilityType: ["Fuel Oil"] },
  { keywords: [/food/i], utilityType: ["Organics"] },
  { keywords: [/recycling|recy/i], utilityType: ["Recycling"] },
  { keywords: [/Diesel|dsl/i], utilityType: ["Diesel"] },
  { keywords: [/Gasoline|gas/i], utilityType: ["Gasoline"] }, // Back to original
  { keywords: [/propane/i], utilityType: ["Propane"] },
  { keywords: [/waste\s|garbage|dumpster|landfill|sharedcontainer/i], utilityType: ["Waste"] },
  { keywords: [/fogsurcharge|^sw\s|sewer|sewage|wastewater|w\/w|(?<!RPZ)backflow|grease|GRP|basedonwater|foodserviceest|^SWR|evaporation|sumppump/i], utilityType: ["Sewer"] },
  { keywords: [/foodserviceest/i], utilityType: ["Storm"] },
  { keywords: [/sprinkler|irriga|sprinkl|fireline/i], utilityType: ["Irrigation"] },
  { keywords: [/water|potable|hydrant|fireline/i, /^[1-2]$/], utilityType: ["Potable", "Irrigation"] },
  { keywords: [/Gas|statecarbonreductioncredit/i], utilityType: ["Gas"] }, // Back to original
  { keywords: [/lighting/i], utilityType: ["Lighting", "Electric"] },
  { keywords: [/electric/i], utilityType: ["Electric"] }
]);

const cleanMeterNum = (num) => (num || '').toString().replace(/[\s-\/]/g, '');
const MIN_SIGNIFICANT_LENGTH = 7;

function meterNumberMatches(li, uam, textractBill) {
  if (!li.meter_num) return false;

  const liMeter = cleanMeterNum(li.meter_num);
  const uamMeters = [cleanMeterNum(uam.meter_num), cleanMeterNum(uam.um_meter_num)];
  const cleanDescription = (li.description || '').replace(/\s/g, "").toLowerCase();
  const isDistribution = /^distribution:/i.test(cleanDescription);
  const isSupply = /^supply:/i.test(cleanDescription);
  const isSupplyDistribution = isSupply || isDistribution

  console.log("About to compare liMeter:", liMeter);
  console.log("To uamMeters:", uamMeters);

  // Exact match logic
  const exactMatch = uamMeters.includes(liMeter);
  if (exactMatch) {
    // For "Distribution:" items, allow match even if utility_company_id differs
    if (isSupplyDistribution || uam.utility_company_id === textractBill.utility_company_id) {
      return true;
    }
  }

  // Substring match logic (if no exact match)
  return uamMeters.some(meter =>
    meter.length >= MIN_SIGNIFICANT_LENGTH &&
    liMeter.length >= MIN_SIGNIFICANT_LENGTH &&
    meter.includes(liMeter)
  ) && (isSupplyDistribution || uam.utility_company_id === textractBill.utility_company_id);
}
function isActiveUam(uam) {
  return uam.status 
    ? ["Active", "Onboarding"].includes(uam.status)
    : uam.active;
}

function matchUtilityType(uam, utilityTypes, li, textractBill, options = {  }) {
  return (utilityTypes.some(type => uam.display_name.includes(type)) || options.singleEnergyType) &&
    (!options.requireMeterMatch || meterNumberMatches(li, uam, textractBill));
}

function findUamWithPriority(li, uams, textractBill, isActiveCheck) {
  const cleanDescription = (li.description || '').replace(/\s/g, "").toLowerCase();
  const isSupply = /^supply:/i.test(cleanDescription);
  const isDistribution = /^distribution:/i.test(cleanDescription);
  const singleEnergyType = [...new Set(uams.map(uam => uam.display_name))].length == 1

  // Find matching utility type from mappings
  for (const { keywords, utilityType } of UTILITY_MAPPINGS) {
    if (keywords.some(keyword => keyword.test(cleanDescription))) {
      const types = Array.isArray(utilityType) ? utilityType : [utilityType];

      // Priority 1: Exact match with meter number and association type
      const firstPassOptions = {
        requireMeterMatch: true,
        singleEnergyType
      }
      if (isSupply) {
        const match = uams.find(uam => 
          isActiveCheck(uam) && 
          uam.association_type === "SUPPLY" && 
          matchUtilityType(uam, types, li, textractBill, firstPassOptions)
        );
        if (match) return match;
      }
      if (isDistribution) {
        const match = uams.find(uam => 
          isActiveCheck(uam) && 
          uam.association_type === "DISTRIBUTION" && 
          matchUtilityType(uam, types, li, textractBill, firstPassOptions)
        );
        if (match) return match;
      }

      // Priority 2: Type match with meter number
      const typeMatch = uams.find(uam => 
        isActiveCheck(uam) && 
        matchUtilityType(uam, types, li, textractBill, firstPassOptions)
      );
      if (typeMatch) return typeMatch;

      const secondPassOptions = {requireMeterNumberMatch: false, singleEnergyType }

      if (isSupply) {
        const match = uams.find(uam => 
          isActiveCheck(uam) && 
          uam.association_type === "SUPPLY" && 
          matchUtilityType(uam, types, li, textractBill, secondPassOptions)
        );
        if (match) return match;
      }

      if (isDistribution) {
        const match = uams.find(uam => 
          isActiveCheck(uam) && 
          uam.association_type === "DISTRIBUTION" && 
          matchUtilityType(uam, types, li, textractBill, secondPassOptions)
        );
        if (match) return match;
      }

      // Priority 3: Type match without meter requirement
      
      const looseTypeMatch = uams.find(uam => 
        isActiveCheck(uam) && 
        matchUtilityType(uam, types, li, textractBill, secondPassOptions)
      );
      if (looseTypeMatch) return looseTypeMatch;
    }
  }

  // Fallbacks
  if (isSupply) {
    const supplyMatch = uams.find(uam => 
      isActiveCheck(uam) && 
      uam.association_type === "SUPPLY" &&
      meterNumberMatches(li, uam, textractBill)
    );
    if (supplyMatch) return supplyMatch;
  }
  if (isDistribution) {
    const distMatch = uams.find(uam => 
      isActiveCheck(uam) && 
      uam.association_type === "DISTRIBUTION" &&
      meterNumberMatches(li, uam, textractBill)
    );
    if (distMatch) return distMatch;
  }

  const meterMatch = uams.find(uam => 
    isActiveCheck(uam) && 
    meterNumberMatches(li, uam, textractBill)
  );
  if (meterMatch) return meterMatch;

  // 2. Association type match
  if (isSupply) {
    const supplyMatch = uams.find(uam => 
      isActiveCheck(uam) && 
      uam.association_type === "SUPPLY"
    );
    if (supplyMatch) return supplyMatch;
  }
  if (isDistribution) {
    const distMatch = uams.find(uam => 
      isActiveCheck(uam) && 
      uam.association_type === "DISTRIBUTION"
    );
    if (distMatch) return distMatch;
  }

  // 3. Utility company match
  return uams.find(uam => 
    isActiveCheck(uam) && 
    uam.utility_company_id === textractBill.utility_company_id
  );
}

function findMatchingUam(li, uams, textractBill, returnId = false) {
  if (!li || !uams?.length) return returnId ? null : null;
  if (!li.description) return returnId ? uams[0].id : uams[0];
  if (uams.length === 1) return returnId ? uams[0].id : uams[0];

  // Try active UAMs first
  let matchedUam = findUamWithPriority(li, uams, textractBill, isActiveUam);
  
  // Fall back to inactive UAMs if no active match found
  if (!matchedUam) {
    matchedUam = findUamWithPriority(li, uams, textractBill, 
      uam => !isActiveUam(uam)
    );
  }

  // Final fallback to first UAM if no match found
  return matchedUam 
    ? (returnId ? matchedUam.id : matchedUam)
    : (returnId ? uams[0].id : uams[0]);
}

// CommonJS export
module.exports = {
  utilityMappings: UTILITY_MAPPINGS,
  meterNumberMatches,
  findMatchingUam
};
