import React, { useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  Typography,
  Tooltip,
  IconButton,
  Container,
  Paper,
  Divider
} from '@material-ui/core';

import { Info as InfoIcon, Lock as LockIcon } from '@material-ui/icons';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import axiosAuth from '../../../utils/axiosWithAuth'; // Adjust the import path as needed
import invoiageLogo from "../../../images/branding/invoiage/invoiage-transparent-logo-small.png";

// Define custom styles using makeStyles
const useStyles = makeStyles((theme) => ({
  logo: {
    display: 'block',
    margin: '0 auto 20px',
    maxWidth: '100%',
    height: 'auto',
  },
  container: {
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(4), // 32px on small screens and up
    },
  },
}));

const CredentialForm = () => {
  const classes = useStyles();
  const { code } = useParams(); // Retrieve personalized code from URL params

  // State for form fields and submission status
  const [onlineAccessUrl, setOnlineAccessUrl] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [twoFaInstructions, setTwoFaInstructions] = useState('');
  const [otherDetails, setOtherDetails] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(false);

    // Basic validation for required fields
    if (!onlineAccessUrl || !username || !password) {
      setError('Missing required information.');
      setLoading(false);
      return;
    }

    // Prepare JSON object for submission
    const formData = {
      personalizedCode: code, // Use code from URL params
      online_access_url: onlineAccessUrl,
      online_access_username: username,
      online_access_password: password,
      twoFaInstructions,
      otherDetails,
    };

    try {
      await axiosAuth().post('/api/credentials', formData);
      setSuccess(true);
    } catch (err) {
      setError('An error occurred while submitting your credentials. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" className={classes.container}>
      <Paper elevation={0} style={{ padding: '20px' }}>
        {/* Company Logo */}
        <img
          src={invoiageLogo}
          alt="Invoiage Logo"
          className={classes.logo}
        />
        <Divider />
        <br></br>
        <form onSubmit={handleSubmit} autoComplete="off">
          <Grid container spacing={2}>
            {/* Form Header */}
            <Grid item xs={12}>
              <Typography variant="h5" align='center'>Utility Account Credential Submission</Typography>

              <br/>
              <Typography variant="body2" align='center' color="textSecondary">
                Help us retrieve your utility bills by providing your account credentials below. 
                Your information is encrypted and stored securely—your trust is our priority.
              </Typography>
            </Grid>

            {/* Online Access URL */}
            <Grid item xs={12}>
              <TextField
                label="Utility Website URL"
                value={onlineAccessUrl}
                onChange={(e) => setOnlineAccessUrl(e.target.value)}
                fullWidth
                autoComplete="nope"
                name="online_access_123_url"
                required
                InputProps={{
                  endAdornment: (
                    <Tooltip title="The website address where you log in to your utility account.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            </Grid>

            {/* Username */}
            <Grid item xs={12}>
              <TextField
                label="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                fullWidth
                required
                autoComplete="off"
                name="userfadsfsdaname_123"
                InputProps={{
                  endAdornment: (
                    <Tooltip title="The username or email you use to log in to your utility account.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            </Grid>

            {/* Password */}
            <Grid item xs={12}>
              <TextField
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                required
                autoComplete="nope" 
                name="password_123"
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Your utility account password. It’s masked and encrypted for your security.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            </Grid>

            {/* 2FA Instructions */}
            <Grid item xs={12}>
              <TextField
                label="Two-Factor Authentication (2FA) Instructions"
                value={twoFaInstructions}
                onChange={(e) => setTwoFaInstructions(e.target.value)}
                fullWidth
                multiline
                rows={3}
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Provide any 2FA details, like security question answers or app instructions.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            </Grid>

            {/* Other Details */}
            <Grid item xs={12}>
              <TextField
                label="Additional Notes"
                value={otherDetails}
                onChange={(e) => setOtherDetails(e.target.value)}
                fullWidth
                multiline
                rows={3}
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Any extra info to help us access your account successfully.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
              >
                {loading ? 'Submitting...' : 'Submit Credentials'}
              </Button>
            </Grid>

            {/* Feedback Messages */}
            {error && (
              <Grid item xs={12}>
                <Typography color="error" variant="body2">
                  {error}
                </Typography>
              </Grid>
            )}
            {success && (
              <Grid item xs={12}>
                <Typography color="primary" variant="body2">
                  Success! Your credentials have been submitted securely.
                </Typography>
              </Grid>
            )}
          </Grid>
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Tooltip title="Learn how Invoiage keeps your data secure">
            <a
              href="https://www.invoiage.com/security" // Replace with your actual URL
              target="_blank"
              rel="noopener noreferrer"
              className={classes.lockIcon}
            >
            <IconButton>
              <LockIcon />
            </IconButton>
          </a>
        </Tooltip>
        </div>
        </form>
        {/* Lock Icon with Tooltip and External Link */}

      </Paper>
    </Container>
  );
};

export default CredentialForm;